import fetchQueue from './queue/fetch-queue';

/**
 *
 * @param payload
 * @param dispatch
 * @param getUrl
 * @param resultAction
 * @param errorAction
 * @returns {Promise<*>}
 *
 * @private
 */
async function fetchCommand ({ payload, dispatch, getUrl, resultAction, errorAction = null }) {
  // fetch() is a new API for requesting server side resource.
  // Note that no cookies are sent by default, even for same-origin
  // requests (credentials: "omit"). We must explicitly turn it on.

  // temporally changed 'same-origin' to 'include' for dev purpose
  // because I'm using remote web-beta.archive-root.ilook.workers.dev end point from local dev env
  // which has different url and as the result 'save-original' is not enough
  // to send cookies
  let url;
  const urlParams = getUrl(payload);
  let crossDomain;
  if (typeof urlParams === 'string') {
    url = urlParams;
    crossDomain = false;
  } else {
    url = urlParams.url;
    crossDomain = urlParams.crossDomain;
  }

  const init = {
    credentials: crossDomain ? 'include' : 'same-origin'
  };
  let response;
  let text;
  try {
    response = await window.fetch(url, init);
    text = await response.text();
    if (!response.ok) {
      throw new Error(`Fail with status: ${response.status} ${response.statusText}`);
    }
    if (text.search('org.archive.util.io.RuntimeIOException') >= 0) {
      throw new Error(`Error: ${text}`);
    }

    let res;
    try {
      res = JSON.parse(text);
    } catch (e) {
      throw new Error(`Fail on parse: ${text}`);
    }
    if ('error' in res) {
      return errorAction && dispatch(errorAction({
        ...payload,
        error: (res.error !== 1 ? res.error : res.description)
      }));
    }
    return await dispatch(resultAction({ ...payload, res }));
  } catch (error) {
    if (window.Raven) {
      window.Raven.captureException(
        error,
        {
          extra: {
            url,
            text,
            headers: response && response.headers,
            status: response && response.status,
            statusText: response && response.statusText
          }
        }
      );
    }
    errorAction && dispatch(errorAction({
      ...payload,
      error: error && error.message
    }));
  }
}

/**
 * Make fetch action.
 * construct url with getUrl.
 *
 * requestAction - will be called on start
 * resultAction - will be called on finish
 *
 * @param getUrl
 * @param isAlreadyInProgress
 * @param requestAction
 * @param resultAction
 * @param errorAction
 * @returns {function(*=): function(*, *): Promise}
 */
export function fetchAction ({ getUrl, isAlreadyInProgress, requestAction, resultAction, errorAction = null }) {
  return (payload) => (dispatch, getState) => {
    if (isAlreadyInProgress && isAlreadyInProgress(getState(), payload)) {
      // already have that request
      return;
    }
    dispatch(requestAction(payload));
    return fetchQueue.add(
      async () => fetchCommand({ payload, dispatch, getUrl, resultAction, errorAction })
    );
  };
}

export function fetchOnSelectorsAction ({ selectors, ignoreIf = null, getUrl, actions }) {
  return (payload = {}) => (dispatch, getState) => {
    const state = getState();
    payload = Object.entries(selectors)
      .map(([key, selector]) => [key, selector(state)])
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, payload);

    if (ignoreIf && ignoreIf(state, payload)) {
      return;
    }

    const requestAction = (data) => ({
      type: actions[0],
      inProgress: true,
      updatedAt: Date.now(),
      ...data
    });

    const resultAction = (data) => ({
      type: actions[1],
      inProgress: false,
      updatedAt: Date.now(),
      ...data
    });

    let errorAction;
    if (actions.length > 2) {
      errorAction = data => ({
        type: actions[2],
        inProgress: false,
        updatedAt: Date.now(),
        ...data
      });
    }

    dispatch(requestAction(payload));

    return fetchQueue.add(
      async () => fetchCommand({ payload, dispatch, getUrl, resultAction, errorAction })
    );
  };
}

export function fetchActionSimplified ({ getUrl, isAlreadyInProgress, actions }) {
  const init = {
    getUrl,
    isAlreadyInProgress,

    requestAction: (data) => ({
      type: actions[0],
      inProgress: true,
      updatedAt: Date.now(),
      ...data
    }),

    resultAction: (data) => ({
      type: actions[1],
      inProgress: false,
      updatedAt: Date.now(),
      ...data
    })
  };

  if (actions.length > 2) {
    init.errorAction = data => ({
      type: actions[2],
      inProgress: false,
      updatedAt: Date.now(),
      ...data
    });
  }

  return fetchAction(init);
}
