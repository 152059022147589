import './calendar-notes.less';

import PropTypes from 'prop-types';
import React from 'react';

const CalendarNoteStatusStat = ({ children, summary, status }) => {
  if (summary[status] > 0) {
    return (
      <li className={`s${status}`}>{children}</li>
    );
  }
  return null;
};
CalendarNoteStatusStat.displayName = 'CalendarNoteStatusStat';
CalendarNoteStatusStat.propTypes = {
  children: PropTypes.string,
  status: PropTypes.string.isRequired,
  summary: PropTypes.object.isRequired
};

const CalendarNotes = ({ summary, url }) => {
  return (
    <div className="wb-calendar-notes">
      <h2>Note</h2>
      <p>This calendar view maps the number of times <strong>{ url }</strong> was crawled by
        the&nbsp;Wayback&nbsp;Machine, <em>not</em> how many times the site was actually updated.
        More info in the <a href="https://help.archive-root.ilook.workers.dev/help/category/the-wayback-machine/">FAQ</a>.
      </p>
      <ul className="color-codes-notes">
        <CalendarNoteStatusStat summary={summary}
          status="5xx">
          Red indicates server errors (5xx).
        </CalendarNoteStatusStat>
        <CalendarNoteStatusStat summary={summary}
          status="4xx">
          Orange indicates that the URL was not found (4xx).
        </CalendarNoteStatusStat>
        <CalendarNoteStatusStat summary={summary}
          status="3xx">
          Green indicates redirects (3xx).
        </CalendarNoteStatusStat>
      </ul>
    </div>
  );
};
CalendarNotes.displayName = 'CalendarNotes';
CalendarNotes.propTypes = {
  summary: PropTypes.object.isRequired,
  url: PropTypes.string
};

export default React.memo(CalendarNotes);
